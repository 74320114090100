html {
  height: 100%;
}
body {
  height: 100%;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.main-modal {
  z-index: 1100;
  background-color: transparent !important;
}
.ReactModal__Overlay--after-open {
  z-index: 1100;
}
.active-profile-button {
  outline: 0;
  background: #f6b547;
  color: #fff;
}
.profile-box .profile-pic {
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: table-cell;
  border-radius: 50%;
}
.pac-container {
  z-index: 1100 !important;
}
.active-border {
  border: 2px solid #f6b547;
}

.active-border p {
  color: #f6b547;
}
.cursor-pointer {
  cursor: pointer;
}

#react-confirm-alert {
  position: relative;
  z-index: 9999;
}
#react-confirm-alert .modal-dialog {
  pointer-events: auto;
}
.add-to-cart-btn {
  min-width: 78px;
}

.restaurantCard {
  min-height: 120px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.restaurantCard::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.restaurantCard h6 {
  z-index: 100;
  margin-bottom: 0;
}

.dialog {
  padding: 20px;
  background: white;
  position: absolute;
  z-index: 500;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 37, 37, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-content {
  background-color: white;
  margin: 0;
  width: 400px;
  height: 400px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
  overflow-y: auto;
}

.close {
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
}

textarea::placeholder {
  color: #a2a2a2 !important;
}

textarea:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.sticky-locationHeader {
  position: sticky;
  top: 100px;
  height: auto;
  z-index: 1;
  background-color: #fff;
}

.sticky-cols {
  position: sticky;
  top: 170px;
  height: calc(100vh - 175px);
  overflow: hidden;
}

.sticky-cols .menu,
.sticky-cols .cart-item,
.sticky-cols .Events_Details,
.sticky-cols .Events_Details-list,
.sticky-cols .stealDealBox {
  height: calc(100% - 50px);
  overflow-y: scroll;
}
.sticky-cols .stealDealBox .cart-item {
  height: unset;
  overflow: unset;
}

.addressLabel {
  height: unset !important;
  margin-top: 10px;
}

.loaderIcon {
  height: 40px;
  width: 40px;
  margin: 30px auto;
  border-width: 0px;
  border-radius: 50%;
  border-top: 3px solid #000000;
  border-right: 3px solid transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.scanner-area {
  width: fit-content;
  height: fit-content;
  max-width: 500px;
  max-height: 500px;
  margin: auto;
  position: relative;
}

.scanner-area video {
  height: 100%;
  width: 100%;
  outline: 4px solid #fff !important;
}

.scanner-area .camera-span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 250px;
  width: 250px;
}

.scanner-area .camera-span::before,
.scanner-area .camera-span::after {
  content: "";
  position: absolute;
  height: 40px;
  width: 40px;
}

.scanner-area .camera-span:nth-child(1)::before {
  left: 0;
  top: 0;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
}

.scanner-area .camera-span:nth-child(1)::after {
  right: 0;
  bottom: 0;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
}

.scanner-area .camera-span:nth-child(2)::before {
  left: 0;
  bottom: 0;
  border-bottom: 4px solid #fff;
  border-left: 4px solid #fff;
}

.scanner-area .camera-span:nth-child(2)::after {
  right: 0;
  top: 0;
  border-top: 4px solid #fff;
  border-right: 4px solid #fff;
}

@media (max-width: 500px) {
  .scanner-area .camera-span {
    height: 150px;
    width: 150px;
  }
  .scanner-area .camera-span::before,
  .scanner-area .camera-span::after {
    height: 20px;
    width: 20px;
    border-width: 2px !important;
  }
}

.or-line {
  margin: 27px 0 -10px 0;
}

.or-line span {
  position: relative;
  top: -26px;
  padding: 0 10px;
}

.apply-coupon-box {
  border: 1px solid #ced4da;
  padding: 10px 16px;
  text-transform: uppercase;
}

.apply-coupon-box img {
  height: 8px;
  transform: rotateZ(-90deg);
}

.coupon-form {
  padding: 20px;
}

.coupon-search-block {
  background-color: #ffffff;
  position: sticky;
  top: -17px;
  padding-top: 12px;
  margin-top: -17px;
  height: 71px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
}

.coupon-search-box {
  margin-bottom: 15px;
  background: #ffffff;
  border-radius: 8px;
}

.read-more-btn {
  color: #e23744 !important;
  font-size: 12px !important;
  cursor: pointer;
}

/* .addressPicker {
    min-height: 80vh !important;
    max-height: 80vh !important;
  } */

.itemRating .star-rating {
  justify-content: end !important;
}

.itemRating button span {
  font-size: 20px;
}

.headerRestaurantSelect {
  border: 1px solid #707070 !important;
  outline: none !important;
  border-radius: 5px;
  z-index: 11;
}

.headerRestaurantSelect,
.headerRestaurantSelect * {
  color: #000000 !important;
  cursor: pointer !important;
}

.headerRestaurantSelect div[id^="react-select"] {
  background-color: #fff !important;
}

.headerRestaurantSelect * {
  outline: none !important;
  border: none !important;
}

.headerRestaurantSelect:focus,
.headerRestaurantSelect *:hover,
.headerRestaurantSelect *:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.loyalty-point-svg {
  height: 18px;
  width: 18px;
  margin-left: 2px;
}

.payAtStore-svg {
  height: 40px;
  width: 40px;
}

.react-confirm-alert {
  overflow-y: auto;
  max-height: 100vh;
}

@media (max-width: 768px) {
  .loyalty-point-svg {
    height: 12px;
    width: 12px;
  }
  .payAtStore-svg {
    height: 30px;
    width: 30px;
  }
}

.currentLocationPicker {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #eeeeee !important;
}

.currentLocationPicker svg {
  height: 30px;
  width: auto;
  margin-right: 18px;
}

.serviceNotAvailable {
  filter: grayscale(1);
  cursor: not-allowed;
}
.myCustomLoginModal {
  z-index: 1100 !important;
}

.no-items-in-store {
  width: 250px;
}
.loading-details {
  display: grid;
  place-items: center;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.scanner-area .cancel-camera {
  position: absolute !important;
  right: -4px;
  padding: 0;
  width: 25px !important;
  background: #fff;
  opacity: 1;
  top: -3px;
  border-radius: 50px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 21px;
  display: flex;
  box-shadow: 0px 0px 4px 0px #000;
  z-index: 10;
}

.tracking-map {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow: hidden;
  box-shadow: 0px -2px 10px -1px #b3b3b3;
}

.nutrition-facts-box {
  border: 1px solid #e6dba8;
  border: 0 20px;
  background: #fffdf5;
  padding: 15px;
  border-radius: 5px;
}
.register-splash-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #aaa;
  font-size: 16px;
}
.heading-h1 {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.custom-sticky {
  position: sticky;
  top: 100px;
  z-index: 10;
}
.item-scroll {
  overflow-y: scroll;
  height: 100vh;
}
.upcoming-outlet-box {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 50%;
  border: 5px solid #bc9838;
}
.text-truncate-outlet {
  padding: 0 40px;
}
.terms-list {
  list-style-type: none;
  padding: 0;
}

.term-number {
  margin-right: 0.5em; /* Adjust the margin as needed */
  display: inline-block;
}

.term-content {
  display: inline-block;
  text-align: left;
}

/* App Suggestion Pop Up css */
.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1100;
}
.custom-modal .custom-modal-content {
  position: absolute;
  bottom: 0;
  left: 0px;
  right: 0px;
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
  z-index: 12;
  border-radius: 15px 15px 0 0;
  /* transition: transform 0.3s ease-in-out; */
  /* transform: translateY(100%); */
}
.custom-modal.open .custom-modal-content {
  /* transform: translateY(0%); */
}
.custom-modal::before {
  content: "";
  background-color: #00000061 !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.custom-modal.open {
  display: block;
  /* transform: translateY(0); */
}

#otpless-floating-button {
  
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 11;
}
#otpless-login-page{    height: 430px !important;}
.whatsAapClose{position: absolute !important;
  right: 0px;
  padding: 0;
  width: 25px !important;
  background:transparent;
  opacity: 1;
  top: 0;
  border-radius: 50px;
  height: 25px;
  align-items: center;
  justify-content: center;
  z-index: 11;
  display: flex;
  font-size: 24px;
  cursor: pointer;
  color: #000;}
#loginBtn {
  width: 100%;
  padding: 15px 20px;
  border: none;
  background-color: #25d366;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
}